<template>
  <IonApp>
    <IonRouterOutlet id="main" />
    <MenusChat />
    <Menus />
    <ProgressVideoUpload />
  </IonApp>
</template>

<script>
import {
  alertController,
  IonApp,
  IonRouterOutlet,
  menuController,
  useIonRouter,
  useBackButton
} from '@ionic/vue';
import { Capacitor } from '@capacitor/core';
import { mapActions, mapGetters } from 'vuex';
import Menus from '@/components/Menus.vue';
import MenusChat from './components/chat/Menus.vue';
import { pushNotifications } from './helpers/pushNotifications';
import ProgressVideoUpload from './components/videos/ProgressVideoUpload.vue';
import { App as CapacitorApp } from '@capacitor/app';
import { AnalyticsService } from '@/plugins/appmetrica';
import { tnSetup } from '@/../vendors/webchat/src/lib/tinode';

export default {
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    Menus,
    MenusChat,
    ProgressVideoUpload
  },
  computed: {
    ...mapGetters('user', ['hasSession', 'session', 'user']),
    ...mapGetters(['error', 'lang'])
  },
  watch: {
    error(newError, prevError) {
      if (newError && newError !== prevError) {
        if (typeof newError === 'string') {
          this.presentAlert();
        } else if (typeof newError === 'object') {
          if (newError?.type === 'Unauthorized') {
            this.$toast(this.$t('PLEASE-LOGIN'), 4000);
            this.logout().then(() => {
              this.$router.replace('/');
            });
          }
          if (newError?.type === 'Network') {
            const { method } = newError;
            const message =
              method === 'post' || method === 'delete' || method === 'put'
                ? this.$t('ERROR-SEND-DATA-INTERNET')
                : this.$t('ERROR-GET-DATA-INTERNET');
            this.$toast(message, 4000);
          }
        }
      }
    },
    lang: {
      immediate: true,
      handler(newLang, oldLang) {
        if (newLang && newLang !== oldLang) {
          this.$i18n.locale = newLang;
          this.$moment.locale(newLang);
        }
      }
    },
    hasSession: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          if (this.hasSession) {
            pushNotifications.OnInit(this.session?.idSession);
            this.getUser()
              .then((user) => {
                pushNotifications.subscribeTo(user.id);
              })
              .catch((e) => {
                console.error(e);
              });

            this.getChatCabinets().then((cabinets) => {
              cabinets?.forEach((c) => {
                this.connectToChat(c?.access, c?.id);
              });
            });
          }
        }
      }
    }
  },
  mounted() {
    let isReallyExit = false;
    const ionRouter = useIonRouter();
    menuController.enable(true, 'profile-menu');
    menuController.enable(true, 'menu-chat-cabinets');

    useBackButton(-1, () => {
      if (!ionRouter.canGoBack() && !document.location.hash) {
        if (isReallyExit) {
          CapacitorApp.exitApp();
        } else {
          this.$toast('Нажмите еще раз для выхода', 1000, 'top');
          isReallyExit = true;
          setTimeout(() => {
            isReallyExit = false;
          }, 1000);
        }
      }
    });
  },
  created() {
    this.analytics = new AnalyticsService();
    this.analytics.initialization();
  },
  methods: {
    ...mapActions(['logout']),
    ...mapActions('user', ['getUser']),
    ...mapActions('chat', ['setUnread', 'getChatCabinets']),
    connectToChat(chatAccess, cabinetId) {
      const token = chatAccess;
      const tn = tnSetup(
        process.env.VUE_APP_CHAT_HOST,
        process.env.VUE_APP_CHAT_SEC === 'true',
        'def',
        'ru',
        true,
        () => {
          token.expires = new Date(token?.expires);
          tn.setAuthToken(token);
          tn.connect()
            .then(() => {
              tn.loginToken(token?.token, {}).then(() => {
                pushNotifications.registerTinodeFCM(tn);
                const me = tn.getMeTopic();
                me.onContactUpdate = () => {
                  this.updateChatContacts(me, cabinetId);
                };
                me.onSubsUpdated = () => {
                  this.updateChatContacts(me, cabinetId);
                };
                me.subscribe(
                  me
                    .startMetaQuery()
                    .withLaterSub()
                    .build()
                ).then(() => {
                  this.updateChatContacts(me, cabinetId);
                });
              });
            })
            .catch((err) => {
              console.error(err);
            });
        },
        Capacitor.getPlatform()
      );
    },
    updateChatContacts(me, cabinetId) {
      let unread = 0;
      me.contacts((c) => {
        unread += c?.unread ?? 0;
      });
      this.setUnread({ unread, cabinetId });
    },
    async presentAlert() {
      try {
        const alert = await alertController.create({
          message: this.error,
          buttons: [this.$t('OK')]
        });
        await alert.present();
        await alert.onDidDismiss();
        this.clearError();
      } catch (e) {
        console.error(e);
      }
    },
    clearError() {
      window.$store.dispatch('setError', null);
    }
  }
};
</script>

<style lang="scss">
@import './theme/main.scss';
</style>
