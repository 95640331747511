import { getProfile, logoutUser } from '../../API/User';

const getDefaultState = () => ({
  session: {
    type: localStorage.type,
    idSession: localStorage.idSession,
    isNew: false
  },
  user: {
    id: null,
    firstname: '',
    lastname: '',
    patronymic: '',
    birth_date: '',
    avatar: null,
    updated_at: 0,
    city: '',
    lat: null,
    lng: null,
    bonuses: 0
  }
});

export default {
  namespaced: true,
  mutations: {
    SET_ID_SESSION: (state, payload) => {
      state.session.idSession = payload;
      localStorage.idSession = payload;
    },
    SET_IS_NEW: (state, payload) => (state.session.isNew = payload),
    SET_USER: (state, payload) => (state.user = payload),
    SET_TYPE: (state, payload) =>
      (localStorage.type = state.session.type = payload),
    RESET_STATE: (state) => Object.assign(state, getDefaultState())
  },
  getters: {
    session: (state) => state.session,
    user: (state) => state.user,
    userId: (state) => state.user?.id,
    hasSession(state) {
      return !!(state.session.idSession && state.session.type);
    }
  },
  actions: {
    destroySession({ commit }) {
      commit('SET_ID_SESSION', '');
    },
    logoutUser() {
      return logoutUser('user');
    },
    getUser() {
      return new Promise((resolve, reject) => {
        getProfile()
          .then((user) => {
            this.commit('user/SET_USER', user);
            resolve(user);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    }
  },
  state: getDefaultState
};
